import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import { Query } from "@graphql-types";
import { colors, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { Container, H1, P } from "@util/standard";
import { BlocksContent } from "@global";
import SEO from "@shared/seo";

const Wrapper = styled(Container)`
  background-color: ${colors.darkBlue};
  width: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  min-height: 80vh;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-bottom: 0;
  }
`;

const MobileTitle = styled(Container)`
  align-items: baseline;
  width: 62%;
  margin: 0 auto;
  flex-direction: column;
  flex-wrap: wrap;
  h1 {
    margin: 1em 0;
  }
  @media only screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const TitleContainer = styled(Container)`
  align-items: center;
  width: 50%;
  margin: 0 auto;
  h1 {
    margin: 1em 0;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const TextContainer = styled(Container)`
  flex-direction: column;
  color: white;
  padding-right: 7%;
  width: 26%;
  p {
    margin-block-start: 0;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    padding-right: 0;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 90%;
    p {
      margin-block-end: 0;
    }
  }
`;

const StyleP = styled(P)`
  font-size: 16px;
`;

const AddressContainer = styled(TextContainer)`
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-bottom: 8%;
    margin-top: -1%;
    P {
      margin-block-start: 5px;
    }
  }
`;

const ContentContainer = styled(Container)`
  align-items: center;
  flex-direction: row;
  text-align: left;
  width: 50%;
  margin: 0 auto;
  column-gap: 30px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
    flex-direction: column;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 70%;
  }
`;

const StyledA = styled.a<{ bold?: boolean; noMargin?: boolean }>`
  font-size: 14px;
  line-height: 25px;
  font-weight: ${props => (props.bold ? "bold" : "normal")};
  margin: ${props => (props.noMargin ? 0 : "0 0 20px 0")};
  color: ${colors.white};
  text-decoration: none;
`;

const ContactPage = () => {
  const { sanityContactUsPage }: Query = useStaticQuery(graphql`
    {
      sanityContactUsPage {
        title
        emailNZ
        numberNZ
        address {
          _rawColumnContent
        }
        _id
        _key
        seo {
          ...sanitySeo
        }
      }
    }
  `);

  if (sanityContactUsPage == null) {
    return null;
  }

  const { emailNZ, numberNZ, title } = sanityContactUsPage;

  return (
    <>
      <SEO seoData={sanityContactUsPage?.seo} />
      <Wrapper>
        <TitleContainer>
          <H1 color="white" fontSize={40} fontWeight="bold">
            {title}
          </H1>
        </TitleContainer>
        <MobileTitle>
          <H1 color="white" fontSize={25} fontWeight="bold">
            {title}
          </H1>
        </MobileTitle>
        <ContentContainer>
          {numberNZ && (
            <TextContainer>
              <StyleP>Number NZ</StyleP>
              <StyledA href={`tel:${numberNZ?.replace(/[()]/g, "")}`}>
                {numberNZ}
              </StyledA>
            </TextContainer>
          )}
          {emailNZ && (
            <TextContainer>
              <StyleP>Email NZ</StyleP>
              <StyledA href={`mailto:${emailNZ}`}>{emailNZ}</StyledA>
            </TextContainer>
          )}
          {sanityContactUsPage?.address && (
            <AddressContainer>
              <StyleP>Address</StyleP>
              <BlocksContent
                blocks={sanityContactUsPage?.address?._rawColumnContent}
              />
            </AddressContainer>
          )}
        </ContentContainer>
      </Wrapper>
    </>
  );
};

export default ContactPage;
